<template>
    <div class="dashboard bookings stats-suppliers">
        <div class="head">
            <div class="title">
                <span> Locations </span>
            </div>
        </div>
        
        <div class="search-booking">
            <div class="item">
                <label for="">Fechas</label>
                <input-date-picker v-model="date" :currentDate="date"></input-date-picker>    
            </div>
            <div class="item">
                <search-supplier @supplier="findSupplier" v-click-outside="closeSuggest" :openSuggestions="openSuggestions"></search-supplier>
            </div>
            <div class="item buttons">
                <vs-button color="primary"  @click="search()" type="filled" icon="search" :disabled="this.date == '' && suppliers.length < 1"></vs-button>
                <vs-button color="warning"  @click="reset()" type="filled" icon="backspace" v-if="date != '' || suppliers.length > 0"></vs-button>
            </div>
            <div class="tags">
                <div class="wrapper-tags">
                    <template v-for="(item, idx) in suppliers">
                        <vs-chip :key="idx" transparent color="primary" closable @click="removeSupplier(item, 1)">{{ item.NAME }}</vs-chip>
                    </template>
                </div>
            </div>
        </div>

        <div class="title book-title">Line Graph</div>

        <div class="box-white">
            <line-chart :key="key" :labels="stats.labels" :options="stats.value" title="Locations" v-if="loaded"></line-chart>
        </div>
        
        <div class="title book-title">Summary</div>

        <table-locations :items="stats.items" @suppCode="getByServTypes"></table-locations>

        <modal :show="modalShow" :dark="true" title="Código Acceso">
            <div slot="modal-body">
                <div class="modal-content" style="padding: 35px 35px 15px">
                    <p>Por favor ingresar el código de acceso</p>
                    <br>
                    <vs-input v-model="codeAccess"  :danger="errorCode" danger-text="Código incorrecto" v-on:keyup.enter="validAccess()"/>
                </div>
            </div>
            <div slot="modal-footer">
                <div class="modal-footer">
                    <vs-button color="primary" type="border" @click="validAccess()" :disabled="disabled">Ingresar</vs-button>
                </div>
            </div>
        </modal>
        
        <modal :show="suppDetailModal" :fullWidth="true" :dark="true" title="Distribución por proveedor">
            <div slot="modal-body">
                <div class="modal-content">

                    <div class="switch-showmore">
                        <label for="">Mostrar listado servicios</label>
                        <vs-switch v-model="showServiceList"/>
                    </div>

                    <bar-chart :key="`chart-${keyBar}`" :labels="suppServType.labels"  :height="190"
                        :options="suppServType.value" title="Total" v-if="loadedBar && !showServiceList"></bar-chart>

                    <div class="table-wrapper with-border serv-detail" v-if="showServiceList">
                        <vs-table :data="suppServType.items" pagination max-items="10">
                            <template slot="thead">
                                <vs-th sort-key="SERVICE">Tipo</vs-th>
                                <vs-th sort-key="SuppName">Proveedor</vs-th>
                                <vs-th sort-key="DESCRIPTION">Servicio</vs-th>
                                <vs-th sort-key="qty" class="center">Qty</vs-th>
                            </template>
                            <template slot-scope="{ data }">
                                <template :data="tr"  v-for="(tr, indextr) in data">
                                    <vs-tr :key="indextr">
                                        <vs-td :data="data[indextr].SERVICE"> 
                                            {{ data[indextr].SERVICE}}
                                        </vs-td>
                                        <vs-td :data="data[indextr].SuppName"> 
                                            {{ data[indextr].SuppName}}
                                        </vs-td>
                                        <vs-td :data="data[indextr].DESCRIPTION"> 
                                            {{ data[indextr].DESCRIPTION}}
                                        </vs-td>
                                    <vs-td :data="data[indextr].qty" class="center"> 
                                            {{ data[indextr].qty }}
                                        </vs-td>
                                    </vs-tr>
                                </template>
                            </template>
                        </vs-table>
                    </div>

                </div>
            </div>
            <div slot="modal-footer">
                <div class="modal-footer">
                    <vs-button color="success" type="border" @click="closeSuppDetailModal">Cerrar</vs-button>
                </div>
            </div>
        </modal>

    </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
import tableLocations from '@/components/Tables/Stats/Locations.vue'
import BookingService from '@/services/BookingService'
import modal from "@/components/Modals/Basic.vue";
import searchSupplier from '@/components/findSupplier.vue'
import inputDatePicker from '@/components/Inputs/input-date-range.vue'
const  { mapActions: mapAppSettingActions } = createNamespacedHelpers('appSetting')

import lineChart  from '@/components/Charts/Line'
import barChart  from '@/components/Charts/Bar'

export default {
    name: "ServiceByBookinView",
    components: {
        modal,
        barChart,
        lineChart,
        tableLocations,
        inputDatePicker,
        searchSupplier,
    },
    data () {
        return {
            key: 1,
            totalPost: 26,
            date: "",
            value: "All",
            modalShow: true,
            codeAccess: "",
            errorCode: false,
            status: "",
            disabled: false,
            stats: [],
            loaded:false,
            
            suppliers: [],
            openSuggestions: true,
            suppDetailModal: false,
            suppServType: [],
            keyBar: 1,
            loadedBar: false,
            showServiceList: false
            
        }
    },
    async created () {
        let code =  JSON.parse(localStorage.getItem('CDG'));
        this.codeAccess = code
        if(this.codeAccess != "" || this.disabled != undefined){
            this.validAccess();
        }
        await this.getStatsLocations(); 
    },
    directives: {
        'click-outside': {
            bind: function (el, binding, vnode) {
                console.log(el)
                el.clickOutsideEvent = (event) => {
                    if (!(el === event.target || el.contains(event.target))) {
                        vnode.context[binding.expression](event);
                    }
                };
                document.body.addEventListener("click", el.clickOutsideEvent);
            }
        }
    },
    methods: {
        ...mapAppSettingActions(["LOADING_STATUS"]),
        async getStatsLocations(){            
            this.LOADING_STATUS(true);
            let response = await BookingService.getStatsLocations({
                "begDate" : this.date != '' ? this.date.start : "", //opcional
                "endDate" : this.date != '' ? this.date.end : "",//opcional
                "suppliers": this.suppliers,
            });            
            if((response.status/100) == 2){
                this.stats  = response.data
                this.loaded = true
                this.key++
            }            
            this.LOADING_STATUS(false);
        },
        search() {
            this.getStatsLocations()
        },
        reset() {
            this.date = "";
            this.getStatsLocations()
        },
        validAccess() {
            if(this.codeAccess == "asuaire2022") {
                this.modalShow = false
                this.status = "done"
                localStorage.setItem('CDG', JSON.stringify(this.codeAccess))
            } else {
                this.errorCode = true
            }
        },
        findSupplier(response) {
            if(this.suppliers.findIndex(item => item.CODE == response.CODE) < 0){
                this.suppliers.push(response)
            }
        },
        removeSupplier(item) {
            this.suppliers.splice(this.suppliers.indexOf(item), 1)
        },
        closeSuggest(){
            this.openSuggestions = !this.openSuggestions
        },
        async getByServTypes(code){
            console.log(code);
            this.LOADING_STATUS(true);
            let response = await BookingService.getStatsLocationsBySupplier({
                "begDate" : this.date != '' ? this.date.start : "", //opcional
                "endDate" : this.date != '' ? this.date.end : "",//opcional
                "loc":  code,
            });      
            if((response.status/100) == 2){
               this.suppServType  = response.data
               this.loadedBar = true;
               this.keyBar++;
               this.suppDetailModal = true

            }            
            this.LOADING_STATUS(false);
               
        },
        closeSuppDetailModal(){
            this.suppDetailModal = false
            this.key++
        }
    }
}
</script>