<template>
    <v-date-picker v-model="date" is-range @input="updateVal" :popover="{ placement: 'top', visibility: 'click' }">
        <template v-slot="{ inputValue, inputEvents }">
            <div class="wprapper-date input-row">
                <vs-input :value="inputValue.start != null ? inputValue.start +' - '+ inputValue.end : ''" v-on="inputEvents.start"/>
                <div class="icon"><feather type="calendar"></feather></div>
            </div>
        </template>
    </v-date-picker>
</template>
<script>
export default {
  props: {
    currentDate:{  }
  },
  data () {
    return {
      date: this.currentDate,
    }
  },
  watch: {
    currentDate (val) {
      this.date = val
    }
  },
  methods: {
    inputEventss(sss){
      console.log(sss)
    },
    updateVal () {
        console.log('ssss')
      this.$emit('input', this.date)
    }
  }
}
</script>
