<template>
  <Bar
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :width="width"
    :height="height"
  />
</template>

<script>
// import { Bar } from 'vue-chartjs'
import { Bar } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'BarChart',
  components: { Bar },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Object,
      default: () => {}
    },
    labels: {
        type: Array,
        default: () => []
    },
    options: {
        type: Array,
        default: () => []
    },
    title: {
        type: String,
        default: ""
    },
    axis: {
      type: String,
      default: 'x'
    },
    dataChart: {
      type: Array,
      default: () => []
    },
    backgroundColors: {
      type: Array,
      default: () => ['#1a2679', '#1ac983', '#fac71e', '#1a75d6', '#fc573b']
    }
  },
  watch:  {
      dataChart () {
         this.formartDataset();
      },
      labels (val){
        console.log(1)
          this.chartData.labels = val
      },
      options (val){
        console.log(2)
          this.chartData.datasets[0].data = val;
          this.chartData.datasets[0].label = this.title;
      },
  },
  mounted () {
    this.formartDataset();
  },
  data() {
    return {
      chartData: {
        labels: this.labels,
        datasets: [], 
        // [ 
        //   { 
        //     label: this.title,
        //     backgroundColor: ['#1a2679', '#1ac983', '#fac71e', '#1a75d6', '#fc573b'],
        //     data: this.options 
        //   } 
        // ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: this.axis,
        plugins:{
          legend: {
            display: this.title == '' ? false : true,
          },
        },
      }
    }
  },
  methods: {
    formartDataset(){
      let newDataSet = []
        for (let i = 0; i < this.dataChart.length; i++) {
          newDataSet.push(
            {
              label: this.dataChart[i].label,
              backgroundColor: this.dataChart[i].backgroundColors != undefined ? this.dataChart[i].backgroundColors  : this.backgroundColors,
              data: this.dataChart[i].data,
            }
          );
        }
        this.chartData.datasets = [...newDataSet];
    }
  }
}
</script>