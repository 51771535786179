
import Http from "../helpers/Http";

class CatalogService {
  
  async getConsultants  (data) {
    let response = await Http.post("/getConsultants", data);
    if( (response.status / 100) == 2) {
        return response.data
    }
  }

  getAgencies  (data) {
    return Http.post("/getAgencies", data);
    // if( (response.status / 100) == 2) {
    //     return response.data
    // }
  }

  getSuppliers (data) {
    return Http.post("/getSuppliers", data);
    // if( (response.status / 100) == 2) {
    //     return response.data
    // }
  }
  
  async getBookingStatus  (data) {
    let response = await Http.post("/getBookingStatus", data);
    if( (response.status / 100) == 2) {
        return response.data
    }
  }

  async getServiceStatus  (data) {
    let response = await Http.post("/getServiceStatus", data);
    if( (response.status / 100) == 2) {
        return response.data
    }
  }

  async getServiceTypes  (data) {
    let response = await Http.post("/getServiceTypes", data);
    if( (response.status / 100) == 2) {
        return response.data
    }
  }

  async getServiceClass  (data) {
    let response = await Http.post("/getServiceClass", data);
    if( (response.status / 100) == 2) {
        return response.data
    }
  }

  // async getPropertyCategories  () {
  //   let response = await Http.get("/propertyCategories");
  //   if( (response.status / 100) == 2) {
  //       return response.data.categories
  //   }
  // }  

}

export default new CatalogService();